define("skylines/components/search-result-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <table class="table table-striped search-results">
    <tbody>
    {{#each @results as |result|}}
      <SearchResultRow @result={{result}} />
    {{else}}
      <tr>
        <td colspan="4">
          {{#if @searchText}}
            {{t "search.no-results"}}
          {{else}}
            {{t "search.no-search-text"}}
          {{/if}}
        </td>
      </tr>
    {{/each}}
    </tbody>
  </table>
  */
  {
    "id": "xyh+yWUT",
    "block": "{\"symbols\":[\"result\",\"@searchText\",\"@results\"],\"statements\":[[10,\"table\"],[14,0,\"table table-striped search-results\"],[12],[2,\"\\n  \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,3]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"search-result-row\",[],[[\"@result\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[2,\"    \"],[10,\"tr\"],[12],[2,\"\\n      \"],[10,\"td\"],[14,\"colspan\",\"4\"],[12],[2,\"\\n\"],[6,[37,1],[[32,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"          \"],[1,[30,[36,0],[\"search.no-results\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"          \"],[1,[30,[36,0],[\"search.no-search-text\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"if\",\"-track-array\",\"each\"]}",
    "moduleName": "skylines/components/search-result-table.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});
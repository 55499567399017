define("skylines/components/timeline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <ul local-class="list" ...attributes>
    {{#each @events as |event|}}
      <li>
        <TimelineEvent @event={{event}} />
      </li>
    {{/each}}
  </ul>
  */
  {
    "id": "Od/ZoDYi",
    "block": "{\"symbols\":[\"event\",\"&attrs\",\"@events\"],\"statements\":[[11,\"ul\"],[16,0,[31,[[30,[36,0],[\"list\"],[[\"from\"],[\"skylines/components/timeline.module.scss\"]]]]]],[17,2],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"li\"],[12],[2,\"\\n      \"],[8,\"timeline-event\",[],[[\"@event\"],[[32,1]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"local-class\",\"-track-array\",\"each\"]}",
    "moduleName": "skylines/components/timeline.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});
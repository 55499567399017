define("skylines/components/x-flag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <span local-class="flag-{{lowercase @code}}" ...attributes></span>
  
  */
  {
    "id": "bCkqYdze",
    "block": "{\"symbols\":[\"@code\",\"&attrs\"],\"statements\":[[11,\"span\"],[16,0,[31,[[30,[36,2],[[30,[36,1],[\"flag-\",[30,[36,0],[[32,1]],null]],null]],[[\"from\"],[\"skylines/components/x-flag.module.scss\"]]]]]],[17,2],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"lowercase\",\"concat\",\"local-class\"]}",
    "moduleName": "skylines/components/x-flag.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});
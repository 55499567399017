define("skylines/components/plane-icons-composer", ["exports", "ol/style/Icon", "ol/style/Style"], function (_exports, _Icon, _Style) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var STYLES = {
    glider: createStyle({
      src: '/images/glider_symbol.png'
    }),
    motorglider: createStyle({
      src: '/images/motorglider_symbol.png'
    }),
    paraglider: createStyle({
      src: '/images/paraglider_symbol.png'
    }),
    hangglider: createStyle({
      size: [40, 14],
      src: '/images/hangglider_symbol.png'
    })
  };

  function createStyle(_ref) {
    var src = _ref.src,
        _ref$size = _ref.size,
        size = _ref$size === void 0 ? [40, 24] : _ref$size;
    var icon = new _Icon.default({
      size: size,
      src: src,
      rotation: 0,
      rotateWithView: true
    });
    icon.load();
    return new _Style.default({
      image: icon
    });
  }

  var _default = Ember.Component.extend((_dec = Ember._action, (_obj = {
    tagName: '',
    map: null,
    fixes: null,
    fixesObserver: Ember.observer('fixes.@each.pointXY', function () {
      Ember.run.once(this.map, 'render');
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.map.on('postcompose', this.onPostCompose, this);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.map.un('postcompose', this.onPostCompose, this);
    },
    onPostCompose: function onPostCompose(e) {
      this.renderIcons(e.vectorContext);
    },
    renderIcons: function renderIcons(context) {
      this.fixes.forEach(function (fix) {
        var point = fix.get('pointXY');

        if (point) {
          var type = Ember.getWithDefault(fix, 'flight.model.type', 'glider');
          var style = STYLES[type] || STYLES['glider'];
          style.getImage().setRotation(fix.get('heading'));
          context.setStyle(style);
          context.drawGeometry(point);
        }
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "onPostCompose", [_dec], Object.getOwnPropertyDescriptor(_obj, "onPostCompose"), _obj)), _obj)));

  _exports.default = _default;
});
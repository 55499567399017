define("skylines/components/sidebar/tabs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <ul local-class="tabs" role="tablist" ...attributes>
    {{yield (hash Tab=(component "sidebar/tab" selectedTab=@selectedTab onSelect=@onSelect))}}
  </ul>
  */
  {
    "id": "v8ipzDKb",
    "block": "{\"symbols\":[\"&attrs\",\"@onSelect\",\"@selectedTab\",\"&default\"],\"statements\":[[11,\"ul\"],[16,0,[31,[[30,[36,0],[\"tabs\"],[[\"from\"],[\"skylines/components/sidebar/tabs.module.scss\"]]]]]],[24,\"role\",\"tablist\"],[17,1],[12],[2,\"\\n  \"],[18,4,[[30,[36,2],null,[[\"Tab\"],[[30,[36,1],[\"sidebar/tab\"],[[\"selectedTab\",\"onSelect\"],[[32,3],[32,2]]]]]]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"local-class\",\"component\",\"hash\"]}",
    "moduleName": "skylines/components/sidebar/tabs.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});
define("skylines/components/upload-flight-form.module.scss", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "weglide-box": "qL form-group form-control",
    "weglide-label": "qT",
    "weglide-divider": "qz",
    "weglide-user-id": "qa form-group",
    "weglide-birthday": "qH form-group"
  };
  _exports.default = _default;
});
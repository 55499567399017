define("skylines/components/follower-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <BasePage>
    <div class="page-header">
      <h1>{{@user.name}} - {{t "followers"}}</h1>
    </div>
  
    <div class="row">
      <div class="col-md-12">
        <FollowerList @followers={{@followers}} />
      </div>
    </div>
  </BasePage>
  
  */
  {
    "id": "DR5x+31L",
    "block": "{\"symbols\":[\"@user\",\"@followers\"],\"statements\":[[8,\"base-page\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"page-header\"],[12],[2,\"\\n    \"],[10,\"h1\"],[12],[1,[32,1,[\"name\"]]],[2,\" - \"],[1,[30,[36,0],[\"followers\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-md-12\"],[12],[2,\"\\n      \"],[8,\"follower-list\",[],[[\"@followers\"],[[32,2]]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "moduleName": "skylines/components/follower-page.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});
define("skylines/computed/computed-point", ["exports", "ol/geom/Point", "skylines/computed/safe-computed"], function (_exports, _Point, _safeComputed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = computedPoint;

  function computedPoint(key) {
    var layout = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'XYZM';
    return (0, _safeComputed.default)(key, function (coordinate) {
      if (layout === 'XY') {
        coordinate = coordinate.slice(0, 2);
      }

      return new _Point.default(coordinate, layout);
    });
  }
});
define("skylines/routes/notifications", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PER_PAGE = 20;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    queryParams: {
      page: {
        refreshModel: true
      },
      user: {
        refreshModel: true
      },
      type: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel(transition) {
      this.session.requireAuthentication(transition, 'login');
    },
    model: function model(params) {
      var data = {
        page: params.page,
        per_page: PER_PAGE
      };

      if (params.user) {
        data.user = params.user;
      }

      if (params.type) {
        data.type = params.type;
      }

      return this.ajax.request('/api/notifications', {
        data: data
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('perPage', PER_PAGE);
    },
    resetController: function resetController(controller, isExiting) {
      this._super.apply(this, arguments);

      if (isExiting) {
        controller.set('page', 1);
        controller.set('user', null);
        controller.set('type', null);
      }
    },
    actions: {
      loading: function loading(transition) {
        var controller = this.controllerFor('notifications');
        controller.set('loading', true);
        transition.promise.finally(function () {
          controller.set('loading', false);
        });
        return true;
      }
    }
  });

  _exports.default = _default;
});
define("skylines/components/timeline-events/ui", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div local-class="event">
    {{svg-jar @icon local-class="icon" class=(if @smallIcon "fa-svg small" "fa-svg")}}
  
    <div>
      <div local-class="badge-and-time">
        <time
          datetime={{to-iso-string @time}}
          title={{format-time @time format="full"}}
          local-class="time"
        >
          {{format-relative @time}}
        </time>
  
        {{#if @newBadge}}
          <span local-class="new-badge">{{t "new"}}</span>
        {{/if}}
      </div>
  
      <div local-class="text" data-test-text>
        {{@text}}
      </div>
    </div>
  
    <a href={{@url}} local-class="show-button">{{t "show"}}</a>
  </div>
  */
  {
    "id": "fliHQ6yA",
    "block": "{\"symbols\":[\"@smallIcon\",\"@icon\",\"@time\",\"@newBadge\",\"@text\",\"@url\"],\"statements\":[[10,\"div\"],[15,0,[31,[[30,[36,0],[\"event\"],[[\"from\"],[\"skylines/components/timeline-events/ui.module.scss\"]]]]]],[12],[2,\"\\n  \"],[1,[30,[36,4],[[32,2]],[[\"class\"],[[30,[36,3],[[30,[36,2],[[32,1],\"fa-svg small\",\"fa-svg\"],null],\" \",[30,[36,0],[\"icon\"],[[\"from\"],[\"skylines/components/timeline-events/ui.module.scss\"]]]],null]]]]],[2,\"\\n\\n  \"],[10,\"div\"],[12],[2,\"\\n    \"],[10,\"div\"],[15,0,[31,[[30,[36,0],[\"badge-and-time\"],[[\"from\"],[\"skylines/components/timeline-events/ui.module.scss\"]]]]]],[12],[2,\"\\n      \"],[10,\"time\"],[15,0,[31,[[30,[36,0],[\"time\"],[[\"from\"],[\"skylines/components/timeline-events/ui.module.scss\"]]]]]],[15,\"datetime\",[30,[36,5],[[32,3]],null]],[15,\"title\",[30,[36,6],[[32,3]],[[\"format\"],[\"full\"]]]],[12],[2,\"\\n        \"],[1,[30,[36,7],[[32,3]],null]],[2,\"\\n      \"],[13],[2,\"\\n\\n\"],[6,[37,2],[[32,4]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[15,0,[31,[[30,[36,0],[\"new-badge\"],[[\"from\"],[\"skylines/components/timeline-events/ui.module.scss\"]]]]]],[12],[1,[30,[36,1],[\"new\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[15,0,[31,[[30,[36,0],[\"text\"],[[\"from\"],[\"skylines/components/timeline-events/ui.module.scss\"]]]]]],[12],[2,\"\\n      \"],[1,[32,5]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"a\"],[15,0,[31,[[30,[36,0],[\"show-button\"],[[\"from\"],[\"skylines/components/timeline-events/ui.module.scss\"]]]]]],[15,6,[32,6]],[12],[1,[30,[36,1],[\"show\"],null]],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"local-class\",\"t\",\"if\",\"concat\",\"svg-jar\",\"to-iso-string\",\"format-time\",\"format-relative\"]}",
    "moduleName": "skylines/components/timeline-events/ui.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});
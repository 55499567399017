define("skylines/components/flight-barogram", ["exports", "skylines/computed/safe-computed", "skylines/components/base-barogram"], function (_exports, _safeComputed, _baseBarogram) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = _baseBarogram.default.extend({
    flights: null,
    time: null,
    defaultTime: null,
    selection: null,
    activeFlights: Ember.computed('flights.[]', 'selection', function () {
      var flights = this.flights,
          selection = this.selection;
      return flights.filter(function (flight) {
        return !selection || flight.get('id') === selection;
      });
    }),
    passiveFlights: Ember.computed('flights.[]', 'selection', function () {
      var flights = this.flights,
          selection = this.selection;
      return flights.filter(function (flight) {
        return selection && flight.get('id') !== selection;
      });
    }),
    active: Ember.computed('activeFlights.@each.{flot_h,color}', function () {
      return this.activeFlights.map(function (flight) {
        return {
          data: flight.get('flot_h'),
          color: flight.get('color')
        };
      });
    }),
    passive: Ember.computed('passiveFlights.@each.{flot_h,color}', function () {
      return this.passiveFlights.map(function (flight) {
        return {
          data: flight.get('flot_h'),
          color: flight.get('color')
        };
      });
    }),
    enls: Ember.computed('activeFlights.@each.{flot_enl,color}', function () {
      return this.activeFlights.map(function (flight) {
        return {
          data: flight.get('flot_enl'),
          color: flight.get('color')
        };
      });
    }),
    selectedFlight: Ember.computed('flights.@each.id', 'selection', function () {
      if (this.flights.length === 1) {
        return this.flights.firstObject;
      }

      if (this.selection) {
        return this.flights.findBy('id', this.selection);
      }
    }),
    contests: (0, _safeComputed.default)('selectedFlight', function (flight) {
      return flight.get('contests');
    }),
    elevations: (0, _safeComputed.default)('selectedFlight', function (flight) {
      return flight.get('flot_elev');
    }),
    timeInterval: null,
    initFlot: Ember._action(function (element) {
      var _this = this;

      this._initFlot(element);

      this.placeholder.on('plothover', function (event, pos) {
        if (_this.hoverMode) {
          _this.onTimeChange(pos.x / 1000);
        }
      });
      this.placeholder.on('mouseout', function () {
        if (_this.hoverMode) {
          _this.onTimeChange(_this.defaultTime);
        }
      });
      this.placeholder.on('plotclick', function (event, pos) {
        _this.onTimeChange(pos.x / 1000);
      });
    }),
    crosshair: Ember.computed('time', function () {
      var time = this.time;

      if (time === null) {
        return undefined;
      } else if (time === -1) {
        return {
          x: 999999999
        };
      } else {
        return {
          x: time * 1000
        };
      }
    }),
    xaxis: Ember.computed('timeInterval', function () {
      var min, max;

      if (!this.timeInterval) {
        min = max = null;
      } else {
        var _this$timeInterval = _slicedToArray(this.timeInterval, 2),
            start = _this$timeInterval[0],
            end = _this$timeInterval[1];

        min = start * 1000;
        max = end * 1000;
      }

      return {
        mode: 'time',
        timeformat: '%H:%M',
        min: min,
        max: max
      };
    }),
    willDestroy: function willDestroy() {
      var placeholder = this.placeholder;
      placeholder.off('plothover');
      placeholder.off('mouseout');
      placeholder.off('plotclick');
    },
    gridMarkings: Ember.computed('timeHighlight.{start,end}', function () {
      var timeHighlight = this.timeHighlight;

      if (timeHighlight) {
        return [{
          color: '#fff083',
          xaxis: {
            from: timeHighlight.start * 1000,
            to: timeHighlight.end * 1000
          }
        }];
      }
    })
  });

  _exports.default = _default;
});
define("skylines/components/flight-performance-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <h4>{{t "circling-performance"}}</h4>
  <CirclingPerformanceTable @perf={{@performance.circling}} />
  
  <h4>{{t "cruise-performance"}}</h4>
  <CruisePerformanceTable @perf={{@performance.cruise}} />
  */
  {
    "id": "6Ft+rKcV",
    "block": "{\"symbols\":[\"@performance\"],\"statements\":[[10,\"h4\"],[12],[1,[30,[36,0],[\"circling-performance\"],null]],[13],[2,\"\\n\"],[8,\"circling-performance-table\",[],[[\"@perf\"],[[32,1,[\"circling\"]]]],null],[2,\"\\n\\n\"],[10,\"h4\"],[12],[1,[30,[36,0],[\"cruise-performance\"],null]],[13],[2,\"\\n\"],[8,\"cruise-performance-table\",[],[[\"@perf\"],[[32,1,[\"cruise\"]]]],null]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "moduleName": "skylines/components/flight-performance-panel.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});
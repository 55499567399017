define("skylines/utils/contest", ["exports", "ol/format/Polyline", "ol/geom/LineString", "ol/proj"], function (_exports, _Polyline, _LineString, _proj) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

  /**
   * A contest of a flight.
   * @constructor
   * @param {Array<Object>} _contest Scored/Optimised contests.
   *   Such an object must contain: name, turnpoints, times
   *   turnpoints and times are googlePolyEncoded strings.
   * @param {Number} _sfid The SkyLines flight id this contest trace belongs to.
   */
  var Contest = function Contest(_contest) {
    _classCallCheck(this, Contest);

    this.name = _contest.name;
    this.times = (0, _Polyline.decodeDeltas)(_contest.times, 1, 1);
    var turnpoints = (0, _Polyline.decodeDeltas)(_contest.turnpoints, 2);
    this.geometry = new _LineString.default([]);
    var turnpointsLength = turnpoints.length;
    this.isTriangle = this.name.search(/triangle/) !== -1 && turnpointsLength === 5 * 2;

    if (this.isTriangle) {
      for (var i = 2; i < turnpointsLength - 2; i += 2) {
        var point = (0, _proj.fromLonLat)([turnpoints[i + 1], turnpoints[i]]);
        this.geometry.appendCoordinate(point);
      }

      this.geometry.appendCoordinate(this.geometry.getFirstCoordinate());
    } else {
      for (var _i = 0; _i < turnpointsLength; _i += 2) {
        var _point = (0, _proj.fromLonLat)([turnpoints[_i + 1], turnpoints[_i]]);

        this.geometry.appendCoordinate(_point);
      }
    }
  };

  _exports.default = Contest;
});
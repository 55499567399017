define("skylines/templates/settings/tracking", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uw7riofD",
    "block": "{\"symbols\":[],\"statements\":[[8,\"settings-page\",[],[[\"@title\"],[[30,[36,0],[\"live-tracking\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"settings-panels/tracking-key\",[],[[\"@key\"],[[34,1,[\"trackingKey\"]]]],null],[2,\"\\n  \"],[8,\"settings-panels/tracking\",[],[[\"@delay\",\"@callsign\"],[[34,1,[\"trackingDelay\"]],[34,1,[\"trackingCallsign\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"model\"]}",
    "moduleName": "skylines/templates/settings/tracking.hbs"
  });

  _exports.default = _default;
});
define("skylines/templates/settings/password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FRHOwrWm",
    "block": "{\"symbols\":[],\"statements\":[[8,\"settings-page\",[],[[\"@title\"],[[30,[36,0],[\"password\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"settings-panels/password\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "moduleName": "skylines/templates/settings/password.hbs"
  });

  _exports.default = _default;
});
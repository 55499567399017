define("skylines/components/follower-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <ul class="row list-unstyled">
    {{#each @followers as |follower|}}
      <li class="col-md-3 col-sm-4 col-xs-6">
        <FollowerPanel @follower={{follower}} />
      </li>
    {{/each}}
  </ul>
  
  */
  {
    "id": "0qSDiCJ/",
    "block": "{\"symbols\":[\"follower\",\"@followers\"],\"statements\":[[10,\"ul\"],[14,0,\"row list-unstyled\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"li\"],[14,0,\"col-md-3 col-sm-4 col-xs-6\"],[12],[2,\"\\n      \"],[8,\"follower-panel\",[],[[\"@follower\"],[[32,1]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}",
    "moduleName": "skylines/components/follower-list.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});
define("skylines/components/base-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div local-class="content" ...attributes>
    {{yield}}
  
    <p local-class="footer">
      &copy; <LinkTo @route="about.team">{{t "the-skylines-team"}}</LinkTo>
      -
      <LinkTo @route="about.imprint">{{t "imprint"}}</LinkTo>
      -
      <a href="https://github.com/skylines-project/skylines/blob/master/PRIVACY.md" target="_blank"  rel="noopener">{{t "privacy-policy"}}</a>
    </p>
  </div>
  */
  {
    "id": "uueR9TKu",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[16,0,[31,[[30,[36,0],[\"content\"],[[\"from\"],[\"skylines/components/base-page.module.scss\"]]]]]],[17,1],[12],[2,\"\\n  \"],[18,2,null],[2,\"\\n\\n  \"],[10,\"p\"],[15,0,[31,[[30,[36,0],[\"footer\"],[[\"from\"],[\"skylines/components/base-page.module.scss\"]]]]]],[12],[2,\"\\n    © \"],[8,\"link-to\",[],[[\"@route\"],[\"about.team\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,1],[\"the-skylines-team\"],null]]],\"parameters\":[]}]]],[2,\"\\n    -\\n    \"],[8,\"link-to\",[],[[\"@route\"],[\"about.imprint\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,1],[\"imprint\"],null]]],\"parameters\":[]}]]],[2,\"\\n    -\\n    \"],[10,\"a\"],[14,6,\"https://github.com/skylines-project/skylines/blob/master/PRIVACY.md\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener\"],[12],[1,[30,[36,1],[\"privacy-policy\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"local-class\",\"t\"]}",
    "moduleName": "skylines/components/base-page.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});
define("skylines/templates/flights/date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MYsTcxKQ",
    "block": "{\"symbols\":[],\"statements\":[[8,\"flight-list-nav\",[],[[\"@date\"],[[34,0,[\"date\"]]]],null],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"pull-right\"],[12],[2,\"\\n  \"],[8,\"x-pager\",[],[[\"@count\",\"@page\",\"@perPage\"],[[34,0,[\"count\"]],[34,1],50]],null],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[8,\"flight-list\",[],[[\"@flights\",\"@showDate\",\"@sortColumn\",\"@sortOrder\"],[[34,0,[\"flights\"]],false,[34,2],[34,3]]],null],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"pull-right\"],[12],[2,\"\\n  \"],[8,\"x-pager\",[],[[\"@count\",\"@page\",\"@perPage\"],[[34,0,[\"count\"]],[34,1],50]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"model\",\"page\",\"column\",\"order\"]}",
    "moduleName": "skylines/templates/flights/date.hbs"
  });

  _exports.default = _default;
});
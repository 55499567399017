define("skylines/utils/geo-distance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  var RADIUS = 6367009;
  var DEG_TO_RAD = Math.PI / 180;

  function _default(loc1_deg, loc2_deg) {
    var loc1 = [loc1_deg[0] * DEG_TO_RAD, loc1_deg[1] * DEG_TO_RAD];
    var loc2 = [loc2_deg[0] * DEG_TO_RAD, loc2_deg[1] * DEG_TO_RAD];
    var dlon = loc2[0] - loc1[0];
    var dlat = loc2[1] - loc1[1];
    var a = Math.pow(Math.sin(dlat / 2), 2) + Math.cos(loc1[1]) * Math.cos(loc2[1]) * Math.pow(Math.sin(dlon / 2), 2);
    var c = 2 * Math.asin(Math.sqrt(a));
    return RADIUS * c;
  }
});
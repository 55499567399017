define("skylines/computed/computed-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = computedComponent;

  function computedComponent(baseNameProperty) {
    var prefix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
    return Ember.computed(baseNameProperty, function () {
      var componentName = Ember.get(this, baseNameProperty);

      if (!componentName) {
        return;
      }

      var container = Ember.getOwner(this);
      var fullComponentName = "".concat(prefix).concat(componentName);

      if (container.lookup("component:".concat(fullComponentName)) || container.lookup("template:components/".concat(fullComponentName))) {
        return fullComponentName;
      }
    });
  }
});
define("skylines/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qsFMzJbl",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,2],[\"SkyLines\"],null]],[2,\"\\n\\n\"],[8,\"progress-bar\",[],[[],[]],null],[2,\"\\n\"],[8,\"notification-container\",[],[[\"@zindex\"],[\"7000\"]],null],[2,\"\\n\\n\"],[6,[37,4],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"nav-bar\",[],[[\"@onSearch\"],[[32,0,[\"search\"]]]],null],[2,\"\\n  \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\",\"page-title\",\"onFreestyleRoute\",\"if\"]}",
    "moduleName": "skylines/templates/application.hbs"
  });

  _exports.default = _default;
});
define("skylines/computed/is-none", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isNone;

  function isNone(key) {
    return Ember.computed(key, function () {
      return Ember.isNone(this.get(key));
    });
  }
});
define("skylines/templates/about/license", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZChas+Gk",
    "block": "{\"symbols\":[],\"statements\":[[8,\"base-page\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"page-header\"],[12],[2,\"\\n    \"],[10,\"h1\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"license\"],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-md-12\"],[12],[2,\"\\n      \"],[10,\"pre\"],[12],[1,[35,1,[\"content\"]]],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"model\"]}",
    "moduleName": "skylines/templates/about/license.hbs"
  });

  _exports.default = _default;
});
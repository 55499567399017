define("skylines/templates/settings/club", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "u0x6A8rM",
    "block": "{\"symbols\":[],\"statements\":[[8,\"settings-page\",[],[[\"@title\"],[[30,[36,0],[\"change-club\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"settings-panels/choose-club\",[],[[\"@clubs\",\"@clubId\"],[[34,1,[\"clubs\"]],[30,[36,3],[[35,2,[\"club\",\"id\"]]],null]]],null],[2,\"\\n  \"],[8,\"settings-panels/register-club\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"model\",\"account\",\"readonly\"]}",
    "moduleName": "skylines/templates/settings/club.hbs"
  });

  _exports.default = _default;
});
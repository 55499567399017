define("skylines/components/sortable-table-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if (not-eq @column @sortColumn)}}
    <LinkTo @query={{hash column=@column order=(or @defaultOrder "asc")}} @tagName="th" class="sortable" ...attributes>{{yield}}</LinkTo>
  {{else if (eq @sortOrder "asc")}}
    <LinkTo @query={{hash column=@column order="desc"}} @tagName="th" class="sortable" ...attributes>{{svg-jar "chevron-up" class="fa-svg"}} {{yield}}</LinkTo>
  {{else}}
    <LinkTo @query={{hash column=@column order="asc"}} @tagName="th" class="sortable" ...attributes>{{svg-jar "chevron-down" class="fa-svg"}} {{yield}}</LinkTo>
  {{/if}}
  
  */
  {
    "id": "s/7zj78a",
    "block": "{\"symbols\":[\"@column\",\"&attrs\",\"&default\",\"@sortOrder\",\"@defaultOrder\",\"@sortColumn\"],\"statements\":[[6,[37,3],[[30,[36,5],[[32,1],[32,6]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"link-to\",[[24,0,\"sortable\"],[17,2]],[[\"@query\",\"@tagName\"],[[30,[36,0],null,[[\"column\",\"order\"],[[32,1],[30,[36,4],[[32,5],\"asc\"],null]]]],\"th\"]],[[\"default\"],[{\"statements\":[[18,3,null]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[30,[36,2],[[32,4],\"asc\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"link-to\",[[24,0,\"sortable\"],[17,2]],[[\"@query\",\"@tagName\"],[[30,[36,0],null,[[\"column\",\"order\"],[[32,1],\"desc\"]]],\"th\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,1],[\"chevron-up\"],[[\"class\"],[\"fa-svg\"]]]],[2,\" \"],[18,3,null]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"link-to\",[[24,0,\"sortable\"],[17,2]],[[\"@query\",\"@tagName\"],[[30,[36,0],null,[[\"column\",\"order\"],[[32,1],\"asc\"]]],\"th\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,1],[\"chevron-down\"],[[\"class\"],[\"fa-svg\"]]]],[2,\" \"],[18,3,null]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"hash\",\"svg-jar\",\"eq\",\"if\",\"or\",\"not-eq\"]}",
    "moduleName": "skylines/components/sortable-table-header.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});
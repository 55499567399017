define("skylines/components/map-click-handler.module.scss", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "map-click-handler": "cm",
    "box": "cp",
    "registration": "cx badge",
    "nearby-flights-button": "cw cp",
    "location-info-button": "cN cp",
    "location-info": "cv cp",
    "airspace_name": "co",
    "airspace_base": "cn",
    "airspace_top": "ci",
    "wave_name": "cA"
  };
  _exports.default = _default;
});
define("skylines/components/nav-bar-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <@nav.item ...attributes>
    {{#@nav.link-to @target}}
      <span class="visible-sm-inline-block" title={{@title}}>{{svg-jar @icon class="fa-svg large"}}</span>
      {{svg-jar @icon class="fa-svg fixed-width visible-xs-inline-block"}}
      <span class="hidden-sm">{{@title}}</span>
    {{/@nav.link-to}}
  </@nav.item>
  */
  {
    "id": "MtibJsVc",
    "block": "{\"symbols\":[\"@title\",\"@icon\",\"@nav\",\"&attrs\",\"@target\"],\"statements\":[[8,[32,3,[\"item\"]],[[17,4]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],[[32,3,[\"link-to\"]],[32,5]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"visible-sm-inline-block\"],[15,\"title\",[32,1]],[12],[1,[30,[36,0],[[32,2]],[[\"class\"],[\"fa-svg large\"]]]],[13],[2,\"\\n    \"],[1,[30,[36,0],[[32,2]],[[\"class\"],[\"fa-svg fixed-width visible-xs-inline-block\"]]]],[2,\"\\n    \"],[10,\"span\"],[14,0,\"hidden-sm\"],[12],[1,[32,1]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"svg-jar\",\"component\"]}",
    "moduleName": "skylines/components/nav-bar-link.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});
define("skylines/components/loading-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div local-class="spinner">
    <div local-class="bounce1"></div>
    <div local-class="bounce2"></div>
    <div local-class="bounce3"></div>
  </div>
  */
  {
    "id": "0tQLzAqY",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[[30,[36,0],[\"spinner\"],[[\"from\"],[\"skylines/components/loading-page.module.scss\"]]]]]],[12],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[[30,[36,0],[\"bounce1\"],[[\"from\"],[\"skylines/components/loading-page.module.scss\"]]]]]],[12],[13],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[[30,[36,0],[\"bounce2\"],[[\"from\"],[\"skylines/components/loading-page.module.scss\"]]]]]],[12],[13],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[[30,[36,0],[\"bounce3\"],[[\"from\"],[\"skylines/components/loading-page.module.scss\"]]]]]],[12],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"local-class\"]}",
    "moduleName": "skylines/components/loading-page.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});
define("skylines/utils/pad", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = pad;

  function pad(number) {
    return number < 10 ? "0".concat(number) : number;
  }
});
define("skylines/sentry", ["exports", "@sentry/browser", "@sentry/integrations/esm/ember", "skylines/config/environment"], function (_exports, Sentry, _ember, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.startSentry = startSentry;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function startSentry() {
    var integrations = [];

    if (_environment.default.environment === 'production') {
      integrations.push(new _ember.Ember());
    }

    Sentry.init(_objectSpread(_objectSpread({}, _environment.default.sentry), {}, {
      integrations: integrations,
      beforeSend: function beforeSend(event, hint) {
        var error = hint.originalException;

        if (error) {
          // ignore cancelation errors from the Ember router of ember-concurrency
          if (error.name === 'TaskCancelation' || error.name === 'TransitionAborted') {
            return null;
          }
        }

        return event;
      }
    }));
  }
});
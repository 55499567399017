define("skylines/cldrs/da", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*jslint eqeq: true*/
  var _default = [{
    "locale": "da",
    "pluralRuleFunction": function pluralRuleFunction(n, ord) {
      var s = String(n).split("."),
          i = s[0],
          t0 = Number(s[0]) == n;
      if (ord) return "other";
      return n == 1 || !t0 && (i == 0 || i == 1) ? "one" : "other";
    },
    "fields": {
      "year": {
        "displayName": "år",
        "relative": {
          "0": "i år",
          "1": "næste år",
          "-1": "sidste år"
        },
        "relativeTime": {
          "future": {
            "one": "om {0} år",
            "other": "om {0} år"
          },
          "past": {
            "one": "for {0} år siden",
            "other": "for {0} år siden"
          }
        }
      },
      "year-short": {
        "displayName": "år",
        "relative": {
          "0": "i år",
          "1": "næste år",
          "-1": "sidste år"
        },
        "relativeTime": {
          "future": {
            "one": "om {0} år",
            "other": "om {0} år"
          },
          "past": {
            "one": "for {0} år siden",
            "other": "for {0} år siden"
          }
        }
      },
      "month": {
        "displayName": "måned",
        "relative": {
          "0": "denne måned",
          "1": "næste måned",
          "-1": "sidste måned"
        },
        "relativeTime": {
          "future": {
            "one": "om {0} måned",
            "other": "om {0} måneder"
          },
          "past": {
            "one": "for {0} måned siden",
            "other": "for {0} måneder siden"
          }
        }
      },
      "month-short": {
        "displayName": "md.",
        "relative": {
          "0": "denne md.",
          "1": "næste md.",
          "-1": "sidste md."
        },
        "relativeTime": {
          "future": {
            "one": "om {0} md.",
            "other": "om {0} mdr."
          },
          "past": {
            "one": "for {0} md. siden",
            "other": "for {0} mdr. siden"
          }
        }
      },
      "day": {
        "displayName": "dag",
        "relative": {
          "0": "i dag",
          "1": "i morgen",
          "2": "i overmorgen",
          "-2": "i forgårs",
          "-1": "i går"
        },
        "relativeTime": {
          "future": {
            "one": "om {0} dag",
            "other": "om {0} dage"
          },
          "past": {
            "one": "for {0} dag siden",
            "other": "for {0} dage siden"
          }
        }
      },
      "day-short": {
        "displayName": "dag",
        "relative": {
          "0": "i dag",
          "1": "i morgen",
          "2": "i overmorgen",
          "-2": "i forgårs",
          "-1": "i går"
        },
        "relativeTime": {
          "future": {
            "one": "om {0} dag",
            "other": "om {0} dage"
          },
          "past": {
            "one": "for {0} dag siden",
            "other": "for {0} dage siden"
          }
        }
      },
      "hour": {
        "displayName": "time",
        "relative": {
          "0": "i den kommende time"
        },
        "relativeTime": {
          "future": {
            "one": "om {0} time",
            "other": "om {0} timer"
          },
          "past": {
            "one": "for {0} time siden",
            "other": "for {0} timer siden"
          }
        }
      },
      "hour-short": {
        "displayName": "t.",
        "relative": {
          "0": "i den kommende time"
        },
        "relativeTime": {
          "future": {
            "one": "om {0} time",
            "other": "om {0} timer"
          },
          "past": {
            "one": "for {0} time siden",
            "other": "for {0} timer siden"
          }
        }
      },
      "minute": {
        "displayName": "minut",
        "relative": {
          "0": "i det kommende minut"
        },
        "relativeTime": {
          "future": {
            "one": "om {0} minut",
            "other": "om {0} minutter"
          },
          "past": {
            "one": "for {0} minut siden",
            "other": "for {0} minutter siden"
          }
        }
      },
      "minute-short": {
        "displayName": "min.",
        "relative": {
          "0": "i det kommende minut"
        },
        "relativeTime": {
          "future": {
            "one": "om {0} min.",
            "other": "om {0} min."
          },
          "past": {
            "one": "for {0} min. siden",
            "other": "for {0} min. siden"
          }
        }
      },
      "second": {
        "displayName": "sekund",
        "relative": {
          "0": "nu"
        },
        "relativeTime": {
          "future": {
            "one": "om {0} sekund",
            "other": "om {0} sekunder"
          },
          "past": {
            "one": "for {0} sekund siden",
            "other": "for {0} sekunder siden"
          }
        }
      },
      "second-short": {
        "displayName": "sek.",
        "relative": {
          "0": "nu"
        },
        "relativeTime": {
          "future": {
            "one": "om {0} sek.",
            "other": "om {0} sek."
          },
          "past": {
            "one": "for {0} sek. siden",
            "other": "for {0} sek. siden"
          }
        }
      }
    },
    "numbers": {
      "decimal": {
        "long": [[1000, {
          "one": ["0 tusind", 1],
          "other": ["0 tusind", 1]
        }], [10000, {
          "one": ["00 tusind", 2],
          "other": ["00 tusind", 2]
        }], [100000, {
          "one": ["000 tusind", 3],
          "other": ["000 tusind", 3]
        }], [1000000, {
          "one": ["0 million", 1],
          "other": ["0 millioner", 1]
        }], [10000000, {
          "one": ["00 millioner", 2],
          "other": ["00 millioner", 2]
        }], [100000000, {
          "one": ["000 millioner", 3],
          "other": ["000 millioner", 3]
        }], [1000000000, {
          "one": ["0 milliard", 1],
          "other": ["0 milliarder", 1]
        }], [10000000000, {
          "one": ["00 milliarder", 2],
          "other": ["00 milliarder", 2]
        }], [100000000000, {
          "one": ["000 milliarder", 3],
          "other": ["000 milliarder", 3]
        }], [1000000000000, {
          "one": ["0 billion", 1],
          "other": ["0 billioner", 1]
        }], [10000000000000, {
          "one": ["00 billioner", 2],
          "other": ["00 billioner", 2]
        }], [100000000000000, {
          "one": ["000 billioner", 3],
          "other": ["000 billioner", 3]
        }]],
        "short": [[1000, {
          "one": ["0 t", 1],
          "other": ["0 t", 1]
        }], [10000, {
          "one": ["00 t", 2],
          "other": ["00 t", 2]
        }], [100000, {
          "one": ["000 t", 3],
          "other": ["000 t", 3]
        }], [1000000, {
          "one": ["0 mio'.'", 1],
          "other": ["0 mio'.'", 1]
        }], [10000000, {
          "one": ["00 mio'.'", 2],
          "other": ["00 mio'.'", 2]
        }], [100000000, {
          "one": ["000 mio'.'", 3],
          "other": ["000 mio'.'", 3]
        }], [1000000000, {
          "one": ["0 mia'.'", 1],
          "other": ["0 mia'.'", 1]
        }], [10000000000, {
          "one": ["00 mia'.'", 2],
          "other": ["00 mia'.'", 2]
        }], [100000000000, {
          "one": ["000 mia'.'", 3],
          "other": ["000 mia'.'", 3]
        }], [1000000000000, {
          "one": ["0 bio'.'", 1],
          "other": ["0 bio'.'", 1]
        }], [10000000000000, {
          "one": ["00 bio'.'", 2],
          "other": ["00 bio'.'", 2]
        }], [100000000000000, {
          "one": ["000 bio'.'", 3],
          "other": ["000 bio'.'", 3]
        }]]
      }
    }
  }];
  _exports.default = _default;
});
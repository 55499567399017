define("skylines/components/search-result-row.module.scss", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "column-icon": "cu",
    "column-type": "qS"
  };
  _exports.default = _default;
});
define("skylines/templates/users/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EML+du59",
    "block": "{\"symbols\":[],\"statements\":[[8,\"form-page\",[],[[\"@title\"],[[30,[36,0],[\"register\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"register-form\",[],[[\"@transitionTo\"],[[30,[36,1],[[32,0],\"transitionTo\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"action\"]}",
    "moduleName": "skylines/templates/users/new.hbs"
  });

  _exports.default = _default;
});
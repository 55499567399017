define("skylines/templates/flight/change-pilot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uonkMCfx",
    "block": "{\"symbols\":[],\"statements\":[[8,\"form-page\",[],[[\"@title\"],[[30,[36,0],[\"choose-pilot\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"change-pilot-form\",[],[[\"@flightId\",\"@flight\",\"@clubMembers\",\"@onDidSave\"],[[34,1,[\"id\"]],[34,1,[\"flight\"]],[34,1,[\"clubMembers\"]],[30,[36,2],[[32,0],\"transitionToFlight\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"model\",\"action\"]}",
    "moduleName": "skylines/templates/flight/change-pilot.hbs"
  });

  _exports.default = _default;
});
define("skylines/templates/statistics/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sjZcQhuT",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h2\"],[12],[1,[30,[36,0],[\"flights\"],null]],[13],[2,\"\\n\"],[8,\"stats-flights-table\",[],[[\"@years\"],[[34,1,[\"years\"]]]],null],[2,\"\\n\\n\"],[10,\"h2\"],[12],[1,[30,[36,0],[\"distance\"],null]],[13],[2,\"\\n\"],[8,\"stats-distance-table\",[],[[\"@years\"],[[34,1,[\"years\"]]]],null],[2,\"\\n\\n\"],[10,\"h2\"],[12],[1,[30,[36,0],[\"flight-time\"],null]],[13],[2,\"\\n\"],[8,\"stats-duration-table\",[],[[\"@years\"],[[34,1,[\"years\"]]]],null],[2,\"\\n\\n\"],[10,\"h2\"],[12],[1,[30,[36,0],[\"pilots\"],null]],[13],[2,\"\\n\"],[8,\"stats-pilots-table\",[],[[\"@years\",\"@sumPilots\"],[[34,1,[\"years\"]],[34,1,[\"sumPilots\"]]]],null]],\"hasEval\":false,\"upvars\":[\"t\",\"model\"]}",
    "moduleName": "skylines/templates/statistics/index.hbs"
  });

  _exports.default = _default;
});
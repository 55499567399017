define("skylines/utils/locales", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    code: 'de',
    name: 'Deutsch',
    countryCode: 'de'
  }, {
    code: 'en',
    name: 'English',
    countryCode: 'gb'
  }, {
    code: 'es',
    name: 'Español',
    countryCode: 'es'
  }, {
    code: 'fr',
    name: 'Français',
    countryCode: 'fr'
  }, {
    code: 'nl',
    name: 'Nederlands',
    countryCode: 'nl'
  }, {
    code: 'pl',
    name: 'Polski',
    countryCode: 'pl'
  }];
  _exports.default = _default;
});
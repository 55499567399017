define("skylines/templates/page-not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "y+BVbrnE",
    "block": "{\"symbols\":[],\"statements\":[[8,\"base-page\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"page-header\"],[12],[2,\"\\n    \"],[10,\"h1\"],[12],[1,[30,[36,0],[\"page-not-found.title\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-md-12\"],[12],[2,\"\\n      \"],[10,\"p\"],[14,0,\"lead\"],[12],[1,[30,[36,0],[\"page-not-found.text\"],null]],[13],[2,\"\\n      \"],[10,\"p\"],[12],[10,\"code\"],[12],[2,\"URL: /\"],[1,[35,1,[\"wildcard\"]]],[13],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"model\"]}",
    "moduleName": "skylines/templates/page-not-found.hbs"
  });

  _exports.default = _default;
});
define("skylines/templates/flights", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0Gvy7QqQ",
    "block": "{\"symbols\":[],\"statements\":[[8,\"base-page\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"page-header\"],[12],[2,\"\\n    \"],[10,\"h1\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"flights\"],null]],[2,\"\\n\\n\"],[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"small\"],[12],[1,[30,[36,0],[\"loading\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-md-12\"],[12],[2,\"\\n      \"],[1,[30,[36,4],[[30,[36,3],null,null]],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"loading\",\"if\",\"-outlet\",\"component\"]}",
    "moduleName": "skylines/templates/flights.hbs"
  });

  _exports.default = _default;
});
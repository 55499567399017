define("skylines/templates/club/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KOBD/ZPl",
    "block": "{\"symbols\":[],\"statements\":[[8,\"form-page\",[],[[\"@title\"],[[30,[36,0],[\"edit-club\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"club-edit-form\",[],[[\"@club\"],[[34,1]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"club\"]}",
    "moduleName": "skylines/templates/club/edit.hbs"
  });

  _exports.default = _default;
});
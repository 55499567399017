define("skylines/components/nav-bar-search-form.module.scss", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "form": "ch",
    "input": "cf form-control"
  };
  _exports.default = _default;
});
define("skylines/components/circling-performance-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <tr ...attributes>
    <th>{{t (concat "circling-direction." @perf.circlingDirection)}}</th>
    <td class="numeric">
      {{@perf.count}}<br>
      {{format-lift @perf.vario}}
    </td>
    <td class="numeric">
      {{format-seconds @perf.duration}}<br>
      {{format-altitude @perf.altDiff}}
    </td>
  
  </tr>
  */
  {
    "id": "uF1s+h7x",
    "block": "{\"symbols\":[\"&attrs\",\"@perf\"],\"statements\":[[11,\"tr\"],[17,1],[12],[2,\"\\n  \"],[10,\"th\"],[12],[1,[30,[36,1],[[30,[36,0],[\"circling-direction.\",[32,2,[\"circlingDirection\"]]],null]],null]],[13],[2,\"\\n  \"],[10,\"td\"],[14,0,\"numeric\"],[12],[2,\"\\n    \"],[1,[32,2,[\"count\"]]],[10,\"br\"],[12],[13],[2,\"\\n    \"],[1,[30,[36,2],[[32,2,[\"vario\"]]],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"td\"],[14,0,\"numeric\"],[12],[2,\"\\n    \"],[1,[30,[36,3],[[32,2,[\"duration\"]]],null]],[10,\"br\"],[12],[13],[2,\"\\n    \"],[1,[30,[36,4],[[32,2,[\"altDiff\"]]],null]],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"concat\",\"t\",\"format-lift\",\"format-seconds\",\"format-altitude\"]}",
    "moduleName": "skylines/components/circling-performance-row.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});
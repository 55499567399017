define("skylines/utils/iso-date", ["exports", "skylines/utils/pad"], function (_exports, _pad) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isoDate;

  function isoDate(date) {
    return "".concat(date.getFullYear(), "-").concat((0, _pad.default)(date.getMonth() + 1), "-").concat((0, _pad.default)(date.getDate()));
  }
});
define("skylines/components/flight-leg-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <h4>{{t "distance"}}</h4>
  <FlightLegTable @legs={{@legs.classic}} @selection={{@selection}} @onSelect={{@onSelect}} />
  
  <h4>{{t "triangle"}}</h4>
  <FlightLegTable @legs={{@legs.triangle}} @selection={{@selection}} @onSelect={{@onSelect}} />
  */
  {
    "id": "JEdW5lOp",
    "block": "{\"symbols\":[\"@legs\",\"@selection\",\"@onSelect\"],\"statements\":[[10,\"h4\"],[12],[1,[30,[36,0],[\"distance\"],null]],[13],[2,\"\\n\"],[8,\"flight-leg-table\",[],[[\"@legs\",\"@selection\",\"@onSelect\"],[[32,1,[\"classic\"]],[32,2],[32,3]]],null],[2,\"\\n\\n\"],[10,\"h4\"],[12],[1,[30,[36,0],[\"triangle\"],null]],[13],[2,\"\\n\"],[8,\"flight-leg-table\",[],[[\"@legs\",\"@selection\",\"@onSelect\"],[[32,1,[\"triangle\"]],[32,2],[32,3]]],null]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "moduleName": "skylines/components/flight-leg-panel.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});
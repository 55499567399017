define("skylines/components/flight-leg-row", ["exports", "skylines/computed/safe-computed"], function (_exports, _safeComputed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <tr class="small selectable {{if this.selected "selected"}}" ...attributes {{on "click" this.handleClick}}>
    <td class="start">{{num}}</td>
    <td class="numeric">{{format-distance leg.distance decimals=1}}</td>
    <td class="duration hidden-md">{{format-seconds leg.duration}}</td>
    <td class="numeric">{{format-speed speed}}</td>
    <td class="numeric">{{format-number climbPercentage style="percent" maximumFractionDigits=1}}</td>
    <td class="numeric">{{#if (eq climbRate undefined)}}-{{else}}{{format-lift climbRate}}{{/if}}</td>
    <td class="numeric">{{format-number glideRate maximumFractionDigits=1}}</td>
  </tr>
  */
  {
    "id": "Bh11+T0U",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"tr\"],[16,0,[31,[\"small selectable \",[30,[36,2],[[32,0,[\"selected\"]],\"selected\"],null]]]],[17,1],[4,[38,3],[\"click\",[32,0,[\"handleClick\"]]],null],[12],[2,\"\\n  \"],[10,\"td\"],[14,0,\"start\"],[12],[1,[34,4]],[13],[2,\"\\n  \"],[10,\"td\"],[14,0,\"numeric\"],[12],[1,[30,[36,6],[[35,5,[\"distance\"]]],[[\"decimals\"],[1]]]],[13],[2,\"\\n  \"],[10,\"td\"],[14,0,\"duration hidden-md\"],[12],[1,[30,[36,7],[[35,5,[\"duration\"]]],null]],[13],[2,\"\\n  \"],[10,\"td\"],[14,0,\"numeric\"],[12],[1,[30,[36,9],[[35,8]],null]],[13],[2,\"\\n  \"],[10,\"td\"],[14,0,\"numeric\"],[12],[1,[30,[36,11],[[35,10]],[[\"style\",\"maximumFractionDigits\"],[\"percent\",1]]]],[13],[2,\"\\n  \"],[10,\"td\"],[14,0,\"numeric\"],[12],[6,[37,2],[[30,[36,12],[[35,0],[29]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"-\"]],\"parameters\":[]},{\"statements\":[[1,[30,[36,1],[[35,0]],null]]],\"parameters\":[]}]]],[13],[2,\"\\n  \"],[10,\"td\"],[14,0,\"numeric\"],[12],[1,[30,[36,11],[[35,13]],[[\"maximumFractionDigits\"],[1]]]],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"climbRate\",\"format-lift\",\"if\",\"on\",\"num\",\"leg\",\"format-distance\",\"format-seconds\",\"speed\",\"format-speed\",\"climbPercentage\",\"format-number\",\"eq\",\"glideRate\"]}",
    "moduleName": "skylines/components/flight-leg-row.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    tagName: '',
    inf: Infinity,
    leg: null,
    selection: null,
    onSelect: function onSelect() {},
    speed: Ember.computed('leg.{duration,distance}', function () {
      var duration = this.get('leg.duration');

      if (duration > 0) {
        return this.get('leg.distance') / duration;
      } else {
        return 0;
      }
    }),
    climbPercentage: Ember.computed('leg.{duration,climbDuration}', function () {
      var duration = this.get('leg.duration');

      if (duration > 0) {
        return this.get('leg.climbDuration') / duration;
      } else {
        return 0;
      }
    }),
    climbRate: Ember.computed('leg.{climbDuration,climbHeight}', function () {
      var duration = this.get('leg.climbDuration');

      if (duration > 0) {
        return this.get('leg.climbHeight') / duration;
      }
    }),
    glideRate: Ember.computed('leg.{cruiseDistance,cruiseHeight}', function () {
      var distance = this.get('leg.cruiseDistance');
      var height = this.get('leg.cruiseHeight');

      if (Math.abs(height) > 0 && distance && Math.abs(distance / height) < 1000) {
        return distance / -height;
      } else {
        return Infinity;
      }
    }),
    selected: (0, _safeComputed.default)('selection', function (selection) {
      var leg = this.leg;
      return selection.start === leg.start && selection.end === leg.start + leg.duration;
    }),
    handleClick: Ember._action(function () {
      var onSelect = this.onSelect;

      if (this.selected) {
        onSelect(null);
      } else {
        var leg = this.leg;
        onSelect({
          start: leg.start,
          end: leg.start + leg.duration
        });
      }
    })
  }));

  _exports.default = _default;
});
define("skylines/templates/user/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SghPqD37",
    "block": "{\"symbols\":[],\"statements\":[[8,\"user-page\",[],[[\"@user\"],[[34,0]]],null]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "moduleName": "skylines/templates/user/index.hbs"
  });

  _exports.default = _default;
});
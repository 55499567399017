define("skylines/components/cruise-performance-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <table class="table table-condensed">
    <tbody>
    <tr>
      <th>{{t "duration"}}</th>
      <td>{{format-seconds @perf.duration}} ({{format-number @perf.fraction maximumFractionDigits=1}}%)</td>
    </tr>
    <tr>
      <th>&Delta;H</th>
      <td>{{format-altitude @perf.altDiff}}</td>
    </tr>
    <tr>
      <th>{{t "distance"}}</th>
      <td>{{format-distance @perf.distance decimals=1}}</td>
    </tr>
    <tr>
      <th>{{t "avg-vario-abbr"}}</th>
      <td>{{format-lift @perf.vario}}</td>
    </tr>
    <tr>
      <th>{{t "avg-speed-abbr"}}</th>
      <td>{{format-speed @perf.speed}}</td>
    </tr>
    <tr>
      <th><abbr title={{t "glide-rate"}}>{{t "glide-rate-abbr"}}</abbr></th>
      <td>{{format-number @perf.glideRate  maximumFractionDigits=1}}</td>
    </tr>
    <tr>
      <th>{{t "glides"}}</th>
      <td>{{perf.count}}</td>
    </tr>
    </tbody>
  </table>
  */
  {
    "id": "Ulnhy+VA",
    "block": "{\"symbols\":[\"@perf\"],\"statements\":[[10,\"table\"],[14,0,\"table table-condensed\"],[12],[2,\"\\n  \"],[10,\"tbody\"],[12],[2,\"\\n  \"],[10,\"tr\"],[12],[2,\"\\n    \"],[10,\"th\"],[12],[1,[30,[36,0],[\"duration\"],null]],[13],[2,\"\\n    \"],[10,\"td\"],[12],[1,[30,[36,1],[[32,1,[\"duration\"]]],null]],[2,\" (\"],[1,[30,[36,2],[[32,1,[\"fraction\"]]],[[\"maximumFractionDigits\"],[1]]]],[2,\"%)\"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"tr\"],[12],[2,\"\\n    \"],[10,\"th\"],[12],[2,\"ΔH\"],[13],[2,\"\\n    \"],[10,\"td\"],[12],[1,[30,[36,3],[[32,1,[\"altDiff\"]]],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"tr\"],[12],[2,\"\\n    \"],[10,\"th\"],[12],[1,[30,[36,0],[\"distance\"],null]],[13],[2,\"\\n    \"],[10,\"td\"],[12],[1,[30,[36,4],[[32,1,[\"distance\"]]],[[\"decimals\"],[1]]]],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"tr\"],[12],[2,\"\\n    \"],[10,\"th\"],[12],[1,[30,[36,0],[\"avg-vario-abbr\"],null]],[13],[2,\"\\n    \"],[10,\"td\"],[12],[1,[30,[36,5],[[32,1,[\"vario\"]]],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"tr\"],[12],[2,\"\\n    \"],[10,\"th\"],[12],[1,[30,[36,0],[\"avg-speed-abbr\"],null]],[13],[2,\"\\n    \"],[10,\"td\"],[12],[1,[30,[36,6],[[32,1,[\"speed\"]]],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"tr\"],[12],[2,\"\\n    \"],[10,\"th\"],[12],[10,\"abbr\"],[15,\"title\",[30,[36,0],[\"glide-rate\"],null]],[12],[1,[30,[36,0],[\"glide-rate-abbr\"],null]],[13],[13],[2,\"\\n    \"],[10,\"td\"],[12],[1,[30,[36,2],[[32,1,[\"glideRate\"]]],[[\"maximumFractionDigits\"],[1]]]],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"tr\"],[12],[2,\"\\n    \"],[10,\"th\"],[12],[1,[30,[36,0],[\"glides\"],null]],[13],[2,\"\\n    \"],[10,\"td\"],[12],[1,[35,7,[\"count\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"format-seconds\",\"format-number\",\"format-altitude\",\"format-distance\",\"format-lift\",\"format-speed\",\"perf\"]}",
    "moduleName": "skylines/components/cruise-performance-table.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});
define("skylines/templates/flight-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WZT+IIKw",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,5],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"base-page\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"page-header\"],[12],[2,\"\\n      \"],[10,\"h1\"],[12],[1,[30,[36,0],[\"upload-flight\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[8,\"upload-results-form\",[],[[\"@results\",\"@clubMembers\",\"@aircraftModels\",\"@transitionTo\"],[[34,2,[\"results\"]],[34,2,[\"club_members\"]],[34,2,[\"aircraft_models\"]],[30,[36,4],[[32,0],\"transitionTo\"],null]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"form-page\",[],[[\"@title\"],[[30,[36,0],[\"upload-flight\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"upload-flight-form\",[],[[\"@clubMembers\",\"@onUpload\"],[[34,1,[\"clubMembers\"]],[30,[36,4],[[32,0],[30,[36,3],[[35,2]],null]],null]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"model\",\"result\",\"mut\",\"action\",\"if\"]}",
    "moduleName": "skylines/templates/flight-upload.hbs"
  });

  _exports.default = _default;
});
define("skylines/components/base-barogram", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div ...attributes {{did-insert this.initFlot}} {{did-update this.draw this.data}}></div>
  {{did-update this.setCrosshair this.crosshair}}
  {{did-update this.setGridMarkings this.gridMarkings}}
  {{did-update this.setXAxis this.xaxis}}
  */
  {
    "id": "hO2BWypq",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"div\"],[17,1],[4,[38,0],[[32,0,[\"initFlot\"]]],null],[4,[38,1],[[32,0,[\"draw\"]],[32,0,[\"data\"]]],null],[12],[13],[2,\"\\n\"],[1,[30,[36,1],[[32,0,[\"setCrosshair\"]],[32,0,[\"crosshair\"]]],null]],[2,\"\\n\"],[1,[30,[36,1],[[32,0,[\"setGridMarkings\"]],[32,0,[\"gridMarkings\"]]],null]],[2,\"\\n\"],[1,[30,[36,1],[[32,0,[\"setXAxis\"]],[32,0,[\"xaxis\"]]],null]]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"did-update\"]}",
    "moduleName": "skylines/components/base-barogram.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    units: Ember.inject.service(),
    tagName: '',
    flot: null,
    active: null,
    passive: null,
    enls: null,
    contests: null,
    elevations: null,
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    xaxis: {
      mode: 'time',
      timeformat: '%H:%M'
    },
    initFlot: Ember._action(function (element) {
      this._initFlot(element);

      this.setCrosshair(this.crosshair);
    }),
    _initFlot: function _initFlot(element) {
      var units = this.units,
          xaxis = this.xaxis;
      var opts = {
        grid: {
          borderWidth: 0,
          hoverable: true,
          clickable: true,
          autoHighlight: false,
          margin: 5
        },
        xaxis: xaxis,
        yaxes: [{
          min: 0,
          tickFormatter: units.addAltitudeUnit.bind(units)
        }, {
          show: false,
          min: 0,
          max: 1000
        }],
        crosshair: {
          mode: 'x'
        }
      };

      if (this.uploadMode) {
        opts.selection = {
          mode: 'x'
        };
        opts.crosshair = {
          mode: null
        };
      }

      var placeholder = (0, _jquery.default)(element);
      this.set('placeholder', placeholder);
      this.set('flot', _jquery.default.plot(placeholder, [], opts));
    },
    draw: Ember._action(function () {
      var flot = this.flot;
      flot.setData(this.data);
      flot.setupGrid();
      flot.draw();
    }),
    data: Ember.computed('elevations.[]', 'activeTraces.[]', 'passiveTraces.[]', 'enlData.[]', 'contestData.[]', function () {
      var elevations = {
        data: this.elevations,
        color: 'rgb(235, 155, 98)',
        lines: {
          lineWidth: 0,
          fill: 0.8
        }
      };
      var data = [elevations];
      data = data.concat(this.activeTraces);
      data = data.concat(this.passiveTraces);
      data = data.concat(this.enlData);
      data = data.concat(this.contestData.filter(Boolean));
      return data;
    }),
    activeTraces: Ember.computed.map('active.@each.{data,color}', function (trace) {
      return {
        data: trace.data,
        color: trace.color
      };
    }),
    passiveTraces: Ember.computed.map('passive.@each.{data,color}', function (trace) {
      return {
        data: trace.data,
        color: _jquery.default.color.parse(trace.color).add('a', -0.6).toString(),
        shadowSize: 0,
        lines: {
          lineWidth: 1
        }
      };
    }),
    enlData: Ember.computed.map('enls.@each.{data,color}', function (enl) {
      return {
        data: enl.data,
        color: enl.color,
        lines: {
          lineWidth: 0,
          fill: 0.2
        },
        yaxis: 2
      };
    }),
    contestData: Ember.computed.map('contests.@each.{times,color}', function (contest) {
      var times = contest.times;

      if (times.length < 1) {
        return;
      }

      var color = contest.color; // Add the turnpoint markers to the markings array

      var markings = times.map(function (time) {
        return {
          position: time * 1000
        };
      }); // Add the chart series for this contest to the data array

      return {
        marks: {
          show: true,
          lineWidth: 1,
          toothSize: 6,
          color: color,
          fillColor: color
        },
        data: [],
        markdata: markings
      };
    }),
    setCrosshair: Ember._action(function (_ref2) {
      var _ref3 = _slicedToArray(_ref2, 1),
          crosshair = _ref3[0];

      if (crosshair) {
        this.flot.lockCrosshair(crosshair);
      } else {
        this.flot.clearCrosshair();
      }
    }),
    setGridMarkings: Ember._action(function (_ref4) {
      var _ref5 = _slicedToArray(_ref4, 1),
          markings = _ref5[0];

      var options = this.flot.getOptions();
      options.grid.markings = markings;
      this.draw();
    }),
    setXAxis: Ember._action(function (_ref6) {
      var _ref7 = _slicedToArray(_ref6, 1),
          axis = _ref7[0];

      var options = this.flot.getOptions();

      for (var _i2 = 0, _Object$keys = Object.keys(axis); _i2 < _Object$keys.length; _i2++) {
        var key = _Object$keys[_i2];
        options.xaxes[0][key] = axis[key];
      }

      this.draw();
    })
  }));

  _exports.default = _default;
});
define("skylines/templates/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KIaN8KEP",
    "block": "{\"symbols\":[],\"statements\":[[8,\"form-page\",[],[[\"@title\"],[[30,[36,0],[\"login\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"panel-body\"],[12],[2,\"\\n    \"],[8,\"login-form\",[],[[\"@next\"],[[34,1]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"next\"]}",
    "moduleName": "skylines/templates/login.hbs"
  });

  _exports.default = _default;
});
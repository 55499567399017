define("skylines/components/pilot-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PowerSelect
    @options={{pilots}}
    @disabled={{@disabled}}
    @selected={{pilot}}
    @onChange={{action "onChange"}}
    @searchField="name"
  as |pilot|>
    {{#if (eq pilot.id null)}}
      [{{t "unknown-or-other-person"}}]
    {{else}}
      {{pilot.name}}
    {{/if}}
  </PowerSelect>
  
  */
  {
    "id": "hkwllWs1",
    "block": "{\"symbols\":[\"pilot\",\"@disabled\"],\"statements\":[[8,\"power-select\",[],[[\"@options\",\"@disabled\",\"@selected\",\"@onChange\",\"@searchField\"],[[34,1],[32,2],[34,2],[30,[36,3],[[32,0],\"onChange\"],null],\"name\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[32,1,[\"id\"]],null],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    [\"],[1,[30,[36,0],[\"unknown-or-other-person\"],null]],[2,\"]\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[32,1,[\"name\"]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"pilots\",\"pilot\",\"action\",\"eq\",\"if\"]}",
    "moduleName": "skylines/components/pilot-select.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    account: Ember.inject.service(),
    tagName: '',
    clubMembers: null,
    pilotId: null,
    pilots: Ember.computed('account.{user,club}', 'clubMembers.[]', function () {
      var user = this.get('account.user');
      var club = this.get('account.club');
      var clubMembers = this.clubMembers;
      var pilots = [{
        id: null
      }, user];

      if (club && clubMembers) {
        pilots.push({
          groupName: Ember.get(club, 'name'),
          options: clubMembers
        });
      }

      return pilots;
    }),
    pilot: Ember.computed('pilotsWithNull.[]', 'pilotId', function () {
      return this.findPilot(this.pilotId || null);
    }),
    actions: {
      onChange: function onChange(pilot) {
        this.onChange(pilot.id);
      }
    },
    findPilot: function findPilot(id) {
      var pilots = this.pilots;
      var pilot = pilots.findBy('id', id);

      if (pilot) {
        return pilot;
      }

      var clubMembers = pilots.get('lastObject.options');

      if (clubMembers) {
        return clubMembers.findBy('id', id);
      }
    }
  }));

  _exports.default = _default;
});
define("skylines/utils/parse-query-string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = parseQueryString;

  function parseQueryString(qs) {
    if (!qs) {
      return {};
    }

    if (qs[0] === '?') {
      qs = qs.slice(1);
    }

    var RR = Ember.__loader.require('route-recognizer').default;

    var parseQueryString = RR.prototype.parseQueryString;
    return parseQueryString(qs);
  }
});
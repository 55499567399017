define("skylines/templates/flight/change-aircraft", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Cqcm0YX8",
    "block": "{\"symbols\":[],\"statements\":[[8,\"form-page\",[],[[\"@title\"],[[30,[36,0],[\"choose-aircraft\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"change-aircraft-form\",[],[[\"@flightId\",\"@modelId\",\"@registration\",\"@competitionId\",\"@models\",\"@onDidSave\"],[[34,1,[\"id\"]],[30,[36,2],[[35,1,[\"flight\",\"model\",\"id\"]]],null],[30,[36,2],[[35,1,[\"flight\",\"registration\"]]],null],[30,[36,2],[[35,1,[\"flight\",\"competitionId\"]]],null],[34,1,[\"aircraftModels\"]],[30,[36,3],[[32,0],\"transitionToFlight\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"model\",\"readonly\",\"action\"]}",
    "moduleName": "skylines/templates/flight/change-aircraft.hbs"
  });

  _exports.default = _default;
});
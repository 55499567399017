define("skylines/components/user-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <BasePage>
    <div class="page-header">
      <h1>{{@user.name}}</h1>
    </div>
  
    <div class="row">
      <div class="col-md-8 col-md-offset-2">
        <UserDetails @user={{@user}} />
      </div>
    </div>
  
    <div class="row">
      <div class="col-sm-6 col-md-4 col-md-offset-2">
        <UserDistanceFlights @flights={{@user.distanceFlights}} />
      </div>
  
      <div class="col-sm-6 col-md-4">
        <UserQuickStats @stats={{@user.stats}} />
      </div>
    </div>
  
    {{#if @user.takeoffLocations}}
      <div class="row">
        <div class="col-md-12">
          <h2>{{t "takeoff-locations"}}</h2>
          <TakeoffsMap @locations={{@user.takeoffLocations}} style="height: 533px" />
        </div>
      </div>
    {{/if}}
  </BasePage>
  
  */
  {
    "id": "kp40vLaG",
    "block": "{\"symbols\":[\"@user\"],\"statements\":[[8,\"base-page\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"page-header\"],[12],[2,\"\\n    \"],[10,\"h1\"],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-md-8 col-md-offset-2\"],[12],[2,\"\\n      \"],[8,\"user-details\",[],[[\"@user\"],[[32,1]]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-sm-6 col-md-4 col-md-offset-2\"],[12],[2,\"\\n      \"],[8,\"user-distance-flights\",[],[[\"@flights\"],[[32,1,[\"distanceFlights\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"col-sm-6 col-md-4\"],[12],[2,\"\\n      \"],[8,\"user-quick-stats\",[],[[\"@stats\"],[[32,1,[\"stats\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[6,[37,1],[[32,1,[\"takeoffLocations\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-md-12\"],[12],[2,\"\\n        \"],[10,\"h2\"],[12],[1,[30,[36,0],[\"takeoff-locations\"],null]],[13],[2,\"\\n        \"],[8,\"takeoffs-map\",[[24,5,\"height: 533px\"]],[[\"@locations\"],[[32,1,[\"takeoffLocations\"]]]],null],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"if\"]}",
    "moduleName": "skylines/components/user-page.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});
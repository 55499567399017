define("skylines/templates/users/recover", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lj/9kiYA",
    "block": "{\"symbols\":[],\"statements\":[[8,\"form-page\",[],[[\"@title\"],[[30,[36,1],[\"recover-password\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[35,0]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"recover-step2-form\",[],[[\"@recoveryKey\"],[[34,0]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"recover-step1-form\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"key\",\"t\",\"if\"]}",
    "moduleName": "skylines/templates/users/recover.hbs"
  });

  _exports.default = _default;
});
define("skylines/components/user-distance-flight", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <tr ...attributes>
    <td>{{@label}}</td>
    <td class="column-buttons">
      {{#if @flight}}
        <time
          datetime={{to-iso-string @flight.scoreDate}}
          title={{format-date @flight.scoreDate format="ddmmyyyy" timeZone="UTC"}}
        >
          {{format-relative @flight.scoreDate}}
        </time>
        <div class="pull-right">
          <LinkTo @route="flight" @model={{@flight.id}} class="btn btn-default btn-sm">{{t "show"}}</LinkTo>
        </div>
      {{/if}}
    </td>
  </tr>
  */
  {
    "id": "bsbAS9r9",
    "block": "{\"symbols\":[\"@flight\",\"&attrs\",\"@label\"],\"statements\":[[11,\"tr\"],[17,2],[12],[2,\"\\n  \"],[10,\"td\"],[12],[1,[32,3]],[13],[2,\"\\n  \"],[10,\"td\"],[14,0,\"column-buttons\"],[12],[2,\"\\n\"],[6,[37,4],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"time\"],[15,\"datetime\",[30,[36,0],[[32,1,[\"scoreDate\"]]],null]],[15,\"title\",[30,[36,1],[[32,1,[\"scoreDate\"]]],[[\"format\",\"timeZone\"],[\"ddmmyyyy\",\"UTC\"]]]],[12],[2,\"\\n        \"],[1,[30,[36,2],[[32,1,[\"scoreDate\"]]],null]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"pull-right\"],[12],[2,\"\\n        \"],[8,\"link-to\",[[24,0,\"btn btn-default btn-sm\"]],[[\"@route\",\"@model\"],[\"flight\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[1,[30,[36,3],[\"show\"],null]]],\"parameters\":[]}]]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"to-iso-string\",\"format-date\",\"format-relative\",\"t\",\"if\"]}",
    "moduleName": "skylines/components/user-distance-flight.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});
define("skylines/components/aircraft-model-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PowerSelect
    @options={{modelsWithNull}}
    @selected={{model}}
    @searchField="name"
    @onChange={{action "onChange"}}
  as |model|>
    {{#if (eq model.id null)}}
      [{{t "unspecified"}}]
    {{else}}
      {{model.name}}
    {{/if}}
  </PowerSelect>
  
  */
  {
    "id": "GRKhf7Wg",
    "block": "{\"symbols\":[\"model\"],\"statements\":[[8,\"power-select\",[],[[\"@options\",\"@selected\",\"@searchField\",\"@onChange\"],[[34,1],[34,2],\"name\",[30,[36,3],[[32,0],\"onChange\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[32,1,[\"id\"]],null],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    [\"],[1,[30,[36,0],[\"unspecified\"],null]],[2,\"]\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[32,1,[\"name\"]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"modelsWithNull\",\"model\",\"action\",\"eq\",\"if\"]}",
    "moduleName": "skylines/components/aircraft-model-select.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    tagName: '',
    models: null,
    modelId: null,
    modelsWithNull: Ember.computed('models.[]', function () {
      return [{
        id: null
      }].concat(this.models);
    }),
    model: Ember.computed('modelsWithNull.@each.id', 'modelId', function () {
      return this.modelsWithNull.findBy('id', this.modelId || null);
    }),
    actions: {
      onChange: function onChange(model) {
        this.onChange(model.id);
      }
    }
  }));

  _exports.default = _default;
});
define("skylines/utils/add-days", ["exports", "skylines/utils/iso-date"], function (_exports, _isoDate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = addDays;

  function addDays(date) {
    var days = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return (0, _isoDate.default)(result);
  }
});
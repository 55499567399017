define("skylines/components/flight-leg-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @legs}}
    <table class="table table-condensed text-right">
      <thead>
      <tr class="small">
        <th></th>
        <th class="numeric">{{t "distance-abbr"}}</th>
        <th class="numeric hidden-md"><abbr title={{t "duration"}}>&Delta;t</abbr></th>
        <th class="numeric">{{t "avg-speed-abbr"}}</th>
        <th class="numeric">{{t "climb-percentage-abbr"}}</th>
        <th class="numeric">{{t "avg-vario-abbr"}}</th>
        <th class="numeric"><abbr title={{t "glide-rate"}}>{{t "glide-rate-abbr"}}</abbr></th>
      </tr>
      </thead>
      <tbody>
      {{#each @legs as |leg index|}}
        <FlightLegRow @num={{sum index 1}} @leg={{leg}} @selection={{@selection}} @onSelect={{@onSelect}} />
      {{/each}}
      </tbody>
    </table>
  {{else}}
    {{t "no-data"}}
  {{/if}}
  
  */
  {
    "id": "pa85w8of",
    "block": "{\"symbols\":[\"leg\",\"index\",\"@selection\",\"@onSelect\",\"@legs\"],\"statements\":[[6,[37,4],[[32,5]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"table\"],[14,0,\"table table-condensed text-right\"],[12],[2,\"\\n    \"],[10,\"thead\"],[12],[2,\"\\n    \"],[10,\"tr\"],[14,0,\"small\"],[12],[2,\"\\n      \"],[10,\"th\"],[12],[13],[2,\"\\n      \"],[10,\"th\"],[14,0,\"numeric\"],[12],[1,[30,[36,0],[\"distance-abbr\"],null]],[13],[2,\"\\n      \"],[10,\"th\"],[14,0,\"numeric hidden-md\"],[12],[10,\"abbr\"],[15,\"title\",[30,[36,0],[\"duration\"],null]],[12],[2,\"Δt\"],[13],[13],[2,\"\\n      \"],[10,\"th\"],[14,0,\"numeric\"],[12],[1,[30,[36,0],[\"avg-speed-abbr\"],null]],[13],[2,\"\\n      \"],[10,\"th\"],[14,0,\"numeric\"],[12],[1,[30,[36,0],[\"climb-percentage-abbr\"],null]],[13],[2,\"\\n      \"],[10,\"th\"],[14,0,\"numeric\"],[12],[1,[30,[36,0],[\"avg-vario-abbr\"],null]],[13],[2,\"\\n      \"],[10,\"th\"],[14,0,\"numeric\"],[12],[10,\"abbr\"],[15,\"title\",[30,[36,0],[\"glide-rate\"],null]],[12],[1,[30,[36,0],[\"glide-rate-abbr\"],null]],[13],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"flight-leg-row\",[],[[\"@num\",\"@leg\",\"@selection\",\"@onSelect\"],[[30,[36,1],[[32,2],1],null],[32,1],[32,3],[32,4]]],null],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,0],[\"no-data\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"sum\",\"-track-array\",\"each\",\"if\"]}",
    "moduleName": "skylines/components/flight-leg-table.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});
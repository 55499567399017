define("skylines/helpers/multiply", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.multiply = multiply;
  _exports.default = void 0;

  function multiply(numbers) {
    return numbers.reduce(function (a, b) {
      return a * b;
    }, 1);
  }

  var _default = Ember.Helper.helper(multiply);

  _exports.default = _default;
});
define("skylines/formats", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    time: {
      hhmm: {
        hour: '2-digit',
        minute: '2-digit'
      },
      hhmmss: {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      },
      full: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      }
    },
    date: {
      ddmmyyyy: {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      }
    }
  };
  _exports.default = _default;
});
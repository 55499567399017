define("skylines/templates/settings/profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "333Pqp2C",
    "block": "{\"symbols\":[],\"statements\":[[8,\"settings-page\",[],[[\"@title\"],[[30,[36,0],[\"profile\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"settings-panels/profile\",[],[[\"@email\",\"@firstName\",\"@lastName\",\"@distanceUnitIndex\",\"@speedUnitIndex\",\"@liftUnitIndex\",\"@altitudeUnitIndex\"],[[34,1,[\"email\"]],[34,1,[\"firstName\"]],[34,1,[\"lastName\"]],[34,1,[\"distanceUnit\"]],[34,1,[\"speedUnit\"]],[34,1,[\"liftUnit\"]],[34,1,[\"altitudeUnit\"]]]],null],[2,\"\\n\\n  \"],[8,\"settings-panels/delete-account\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"model\"]}",
    "moduleName": "skylines/templates/settings/profile.hbs"
  });

  _exports.default = _default;
});
define("skylines/components/playback-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <button type="button" local-class="play-button" class="ol-unselectable" ...attributes {{on "click" @onToggle}}>
    {{#if @isRunning}}
      <img src="../../images/stop.png">
    {{else}}
      <img src="../../images/play.png">
    {{/if}}
  </button>
  */
  {
    "id": "26Jup4PF",
    "block": "{\"symbols\":[\"&attrs\",\"@onToggle\",\"@isRunning\"],\"statements\":[[11,\"button\"],[16,0,[31,[\"ol-unselectable \",[30,[36,0],[\"play-button\"],[[\"from\"],[\"skylines/components/playback-button.module.scss\"]]]]]],[17,1],[24,4,\"button\"],[4,[38,1],[\"click\",[32,2]],null],[12],[2,\"\\n\"],[6,[37,2],[[32,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"img\"],[14,\"src\",\"../../images/stop.png\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"img\"],[14,\"src\",\"../../images/play.png\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"local-class\",\"on\",\"if\"]}",
    "moduleName": "skylines/components/playback-button.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});
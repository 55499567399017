define("skylines/utils/raf", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.nextAnimationFrame = nextAnimationFrame;

  function nextAnimationFrame() {
    var animationFrameId;
    var promise = new Promise(function (resolve) {
      animationFrameId = requestAnimationFrame(resolve);
    });

    promise.__ec_cancel__ = function () {
      cancelAnimationFrame(animationFrameId);
    };

    return promise;
  }
});
define("skylines/templates/tracking/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "46AAnfvb",
    "block": "{\"symbols\":[],\"statements\":[[8,\"base-page\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"page-header\"],[12],[2,\"\\n    \"],[10,\"h1\"],[12],[1,[30,[36,0],[\"live-tracking\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-md-12\"],[12],[2,\"\\n      \"],[8,\"tracking-tables\",[],[[\"@tracks\",\"@friends\"],[[34,1,[\"tracks\"]],[34,1,[\"friends\"]]]],null],[2,\"\\n\\n      \"],[8,\"link-to\",[[24,0,\"btn btn-default\"]],[[\"@route\"],[\"tracking.info\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,2],[\"question-circle\"],[[\"class\"],[\"fa-svg\"]]]],[2,\" \"],[1,[30,[36,0],[\"how-to-use-live-tracking\"],null]]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"model\",\"svg-jar\"]}",
    "moduleName": "skylines/templates/tracking/index.hbs"
  });

  _exports.default = _default;
});
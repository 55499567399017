define("skylines/components/tracking-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <table local-class="tracking-table">
    <thead>
    <tr>
      <th class="hidden-xs date">{{t "last-fix"}}</th>
      <th>{{t "pilot"}}</th>
      <th>{{t "nearest-airport"}}</th>
      <th class="hidden-xs numeric">{{t "altitude"}}</th>
      <th></th>
    </tr>
    </thead>
  
    <tbody>
    {{#each @tracks as |track|}}
      <TrackingTableRow @track={{track}} />
    {{/each}}
    </tbody>
  </table>
  */
  {
    "id": "jqPUIUYx",
    "block": "{\"symbols\":[\"track\",\"@tracks\"],\"statements\":[[10,\"table\"],[15,0,[31,[[30,[36,0],[\"tracking-table\"],[[\"from\"],[\"skylines/components/tracking-table.module.scss\"]]]]]],[12],[2,\"\\n  \"],[10,\"thead\"],[12],[2,\"\\n  \"],[10,\"tr\"],[12],[2,\"\\n    \"],[10,\"th\"],[14,0,\"hidden-xs date\"],[12],[1,[30,[36,1],[\"last-fix\"],null]],[13],[2,\"\\n    \"],[10,\"th\"],[12],[1,[30,[36,1],[\"pilot\"],null]],[13],[2,\"\\n    \"],[10,\"th\"],[12],[1,[30,[36,1],[\"nearest-airport\"],null]],[13],[2,\"\\n    \"],[10,\"th\"],[14,0,\"hidden-xs numeric\"],[12],[1,[30,[36,1],[\"altitude\"],null]],[13],[2,\"\\n    \"],[10,\"th\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"tracking-table-row\",[],[[\"@track\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"local-class\",\"t\",\"-track-array\",\"each\"]}",
    "moduleName": "skylines/components/tracking-table.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});
define("skylines/components/sidebar/panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if (eq @visibleTab @id)}}
    <div local-class="wrapper">
      <div local-class="panel">
        <h3 local-class="title">{{@title}}</h3>
        <div local-class="content">
          {{yield}}
        </div>
      </div>
    </div>
  {{/if}}
  */
  {
    "id": "IrmJPRuP",
    "block": "{\"symbols\":[\"@title\",\"&default\",\"@id\",\"@visibleTab\"],\"statements\":[[6,[37,2],[[30,[36,1],[[32,4],[32,3]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[31,[[30,[36,0],[\"wrapper\"],[[\"from\"],[\"skylines/components/sidebar/panel.module.scss\"]]]]]],[12],[2,\"\\n    \"],[10,\"div\"],[15,0,[31,[[30,[36,0],[\"panel\"],[[\"from\"],[\"skylines/components/sidebar/panel.module.scss\"]]]]]],[12],[2,\"\\n      \"],[10,\"h3\"],[15,0,[31,[[30,[36,0],[\"title\"],[[\"from\"],[\"skylines/components/sidebar/panel.module.scss\"]]]]]],[12],[1,[32,1]],[13],[2,\"\\n      \"],[10,\"div\"],[15,0,[31,[[30,[36,0],[\"content\"],[[\"from\"],[\"skylines/components/sidebar/panel.module.scss\"]]]]]],[12],[2,\"\\n        \"],[18,2,null],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"local-class\",\"eq\",\"if\"]}",
    "moduleName": "skylines/components/sidebar/panel.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});
define("skylines/components/timeline-events/ui.module.scss", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "event": "ql",
    "icon": "qX",
    "badge-and-time": "qC",
    "new-badge": "qk badge badge-warning",
    "time": "qJ",
    "text": "qs",
    "show-button": "qF btn btn-default btn-sm"
  };
  _exports.default = _default;
});
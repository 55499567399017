define("skylines/components/background-layers", ["exports", "skylines/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <BackgroundLayers::Osm @map={{@map}} />
  <BackgroundLayers::Relief @map={{@map}} />
  <BackgroundLayers::Airspace @map={{@map}} />
  <BackgroundLayers::Mwp @map={{@map}} />
  {{#if this.bingApiKey}}
    <BackgroundLayers::Bing::Hybrid @apiKey={{this.bingApiKey}} @map={{@map}} />
    <BackgroundLayers::Bing::Road @apiKey={{this.bingApiKey}} @map={{@map}} />
  {{/if}}
  {{#if this.mapBoxUrl}}
    <BackgroundLayers::Mapbox @url={{this.mapBoxUrl}} @map={{@map}} />
  {{/if}}
  <BackgroundLayers::Empty @map={{@map}} />
  */
  {
    "id": "/DK1BXUK",
    "block": "{\"symbols\":[\"@map\"],\"statements\":[[8,\"background-layers/osm\",[],[[\"@map\"],[[32,1]]],null],[2,\"\\n\"],[8,\"background-layers/relief\",[],[[\"@map\"],[[32,1]]],null],[2,\"\\n\"],[8,\"background-layers/airspace\",[],[[\"@map\"],[[32,1]]],null],[2,\"\\n\"],[8,\"background-layers/mwp\",[],[[\"@map\"],[[32,1]]],null],[2,\"\\n\"],[6,[37,0],[[32,0,[\"bingApiKey\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"background-layers/bing/hybrid\",[],[[\"@apiKey\",\"@map\"],[[32,0,[\"bingApiKey\"]],[32,1]]],null],[2,\"\\n  \"],[8,\"background-layers/bing/road\",[],[[\"@apiKey\",\"@map\"],[[32,0,[\"bingApiKey\"]],[32,1]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,0],[[32,0,[\"mapBoxUrl\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"background-layers/mapbox\",[],[[\"@url\",\"@map\"],[[32,0,[\"mapBoxUrl\"]],[32,1]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[8,\"background-layers/empty\",[],[[\"@map\"],[[32,1]]],null]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "skylines/components/background-layers.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    tagName: '',
    mapSettings: Ember.inject.service(),
    mapSettingsObserver: Ember.observer('mapSettings.baseLayer', 'mapSettings.overlayLayers.[]', function () {
      Ember.run.once(this, 'updateLayerVisibilities');
    }),
    mapBoxUrl: _environment.default.MAPBOX_TILE_URL,
    bingApiKey: _environment.default.BING_API_KEY,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      Ember.run.next(function () {
        return _this.updateLayerVisibilities();
      });
    },
    updateLayerVisibilities: function updateLayerVisibilities() {
      var mapSettings = this.mapSettings;
      var baseLayerNames = mapSettings.get('baseLayer');
      var overlayLayerNames = mapSettings.get('overlayLayers');
      var layers = this.map.getLayers().getArray().filter(function (layer) {
        return layer.get('display_in_layer_switcher');
      });
      var baseLayers = layers.filter(function (layer) {
        return layer.get('base_layer');
      });
      baseLayers.forEach(function (layer) {
        layer.setVisible(layer.get('name') === baseLayerNames);
      });

      if (!baseLayers.find(function (layer) {
        return layer.get('name') === baseLayerNames;
      })) {
        baseLayers.find(function (layer) {
          return layer.get('name') === 'OpenStreetMap';
        }).setVisible(true);
      }

      var overlayLayers = layers.filter(function (layer) {
        return !layer.get('base_layer');
      });
      overlayLayers.forEach(function (layer) {
        layer.setVisible(overlayLayerNames.includes(layer.get('name')));
      });
    }
  }));

  _exports.default = _default;
});
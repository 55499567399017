define("skylines/components/cesium-plane-models", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#each @fixes as |fix|}}
    {{#if fix.coordinate}}
      <CesiumPlaneModel @scene={{@scene}} @coordinate={{fix.coordinate}} @heading={{fix.heading}} />
    {{/if}}
  {{/each}}
  */
  {
    "id": "Nw1CDfbp",
    "block": "{\"symbols\":[\"fix\",\"@scene\",\"@fixes\"],\"statements\":[[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,0],[[32,1,[\"coordinate\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"cesium-plane-model\",[],[[\"@scene\",\"@coordinate\",\"@heading\"],[[32,2],[32,1,[\"coordinate\"]],[32,1,[\"heading\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"-track-array\",\"each\"]}",
    "moduleName": "skylines/components/cesium-plane-models.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});
define("skylines/templates/tracking/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pV8j7og1",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,5],[[30,[36,4],[[35,0,[\"pilots\",\"length\"]],1],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,3],[[30,[36,2],[\"live-tracking-of-PILOT\"],[[\"pilot\"],[[35,0,[\"pilots\",\"0\",\"name\"]]]]]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,5],[[30,[36,4],[[35,0,[\"pilots\",\"length\"]],2],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,3],[[30,[36,2],[\"live-tracking-of-PILOT-and-PILOT2\"],[[\"pilot\",\"pilot2\"],[[35,0,[\"pilots\",\"0\",\"name\"]],[35,0,[\"pilots\",\"1\",\"name\"]]]]]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,3],[[30,[36,2],[\"live-tracking-of-PILOT-and-NUM-other-pilots\"],[[\"pilot\",\"num\"],[[35,0,[\"pilots\",\"0\",\"name\"]],[30,[36,1],[[35,0,[\"pilots\",\"length\"]],-1],null]]]]],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"div\"],[14,0,\"fullscreen-below-nav-bar\"],[12],[2,\"\\n  \"],[8,\"tracking-page\",[],[[\"@id\",\"@pilots\",\"@flights\"],[\"fullscreen-content\",[34,0,[\"pilots\"]],[34,0,[\"flights\"]]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"sum\",\"t\",\"page-title\",\"eq\",\"if\"]}",
    "moduleName": "skylines/templates/tracking/details.hbs"
  });

  _exports.default = _default;
});
define("skylines/components/x-flag.module.scss", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "flag-zw": "qw",
    "flag-zm": "qN",
    "flag-za": "qv",
    "flag-yt": "qo",
    "flag-ye": "qn",
    "flag-ws": "qi",
    "flag-wf": "qA",
    "flag-wales": "qY",
    "flag-vu": "qh",
    "flag-vn": "qf",
    "flag-vi": "qK",
    "flag-vg": "qG",
    "flag-ve": "qr",
    "flag-vc": "qb",
    "flag-va": "qy",
    "flag-uz": "qU",
    "flag-uy": "qj",
    "flag-us": "qW",
    "flag-um": "qu",
    "flag-ug": "ZS",
    "flag-ua": "Zc",
    "flag-tz": "Zq",
    "flag-tw": "ZZ",
    "flag-tv": "ZR",
    "flag-tt": "ZQ",
    "flag-tr": "Ze",
    "flag-to": "ZB",
    "flag-tn": "ZE",
    "flag-tm": "Zd",
    "flag-tl": "ZI",
    "flag-tk": "Zt",
    "flag-tj": "ZM",
    "flag-th": "ZP",
    "flag-tg": "Zl",
    "flag-tf": "ZX",
    "flag-td": "ZC",
    "flag-tc": "Zk",
    "flag-sz": "ZJ",
    "flag-sy": "Zs",
    "flag-sv": "ZF",
    "flag-st": "Zg",
    "flag-sr": "ZD",
    "flag-so": "ZV",
    "flag-sn": "ZL",
    "flag-sm": "ZT",
    "flag-sl": "Zz",
    "flag-sk": "Za",
    "flag-sj": "ZH",
    "flag-si": "ZO",
    "flag-sh": "Zm",
    "flag-sg": "Zp",
    "flag-se": "Zx",
    "flag-sd": "Zw",
    "flag-scotland": "ZN",
    "flag-sc": "Zv",
    "flag-sb": "Zo",
    "flag-sa": "Zn",
    "flag-rw": "Zi",
    "flag-ru": "ZA",
    "flag-rs": "ZY",
    "flag-ro": "Zh",
    "flag-re": "Zf",
    "flag-qa": "ZK",
    "flag-py": "ZG",
    "flag-pw": "Zr",
    "flag-pt": "Zb",
    "flag-ps": "Zy",
    "flag-pr": "ZU",
    "flag-pn": "Zj",
    "flag-pm": "ZW",
    "flag-pl": "Zu",
    "flag-pk": "RS",
    "flag-ph": "Rc",
    "flag-pg": "Rq",
    "flag-pf": "RZ",
    "flag-pe": "RR",
    "flag-pa": "RQ",
    "flag-om": "Re",
    "flag-nz": "RB",
    "flag-nu": "RE",
    "flag-nr": "Rd",
    "flag-no": "RI",
    "flag-nl": "Rt",
    "flag-ni": "RM",
    "flag-ng": "RP",
    "flag-nf": "Rl",
    "flag-ne": "RX",
    "flag-nc": "RC",
    "flag-na": "Rk",
    "flag-mz": "RJ",
    "flag-my": "Rs",
    "flag-mx": "RF",
    "flag-mw": "Rg",
    "flag-mv": "RD",
    "flag-mu": "RV",
    "flag-mt": "RL",
    "flag-ms": "RT",
    "flag-mr": "Rz",
    "flag-mq": "Ra",
    "flag-mp": "RH",
    "flag-mo": "RO",
    "flag-mn": "Rm",
    "flag-mm": "Rp",
    "flag-ml": "Rx",
    "flag-mk": "Rw",
    "flag-mh": "RN",
    "flag-mg": "Rv",
    "flag-me": "Ro",
    "flag-md": "Rn",
    "flag-mc": "Ri",
    "flag-ma": "RA",
    "flag-ly": "RY",
    "flag-lv": "Rh",
    "flag-lu": "Rf",
    "flag-lt": "RK",
    "flag-ls": "RG",
    "flag-lr": "Rr",
    "flag-lk": "Rb",
    "flag-li": "Ry",
    "flag-lc": "RU",
    "flag-lb": "Rj",
    "flag-la": "RW",
    "flag-kz": "Ru",
    "flag-ky": "QS",
    "flag-kw": "Qc",
    "flag-kr": "Qq",
    "flag-kp": "QZ",
    "flag-kn": "QR",
    "flag-km": "QQ",
    "flag-ki": "Qe",
    "flag-kh": "QB",
    "flag-kg": "QE",
    "flag-ke": "Qd",
    "flag-jp": "QI",
    "flag-jo": "Qt",
    "flag-jm": "QM",
    "flag-it": "QP",
    "flag-is": "Ql",
    "flag-ir": "QX",
    "flag-iq": "QC",
    "flag-io": "Qk",
    "flag-in": "QJ",
    "flag-il": "Qs",
    "flag-ie": "QF",
    "flag-id": "Qg",
    "flag-hu": "QD",
    "flag-ht": "QV",
    "flag-hr": "QL",
    "flag-hn": "QT",
    "flag-hm": "Qz",
    "flag-hk": "Qa",
    "flag-gy": "QH",
    "flag-gw": "QO",
    "flag-gu": "Qm",
    "flag-gt": "Qp",
    "flag-gs": "Qx",
    "flag-gr": "Qw",
    "flag-gq": "QN",
    "flag-gp": "Qv",
    "flag-gn": "Qo",
    "flag-gm": "Qn",
    "flag-gl": "Qi",
    "flag-gi": "QA",
    "flag-gh": "QY",
    "flag-gf": "Qh",
    "flag-ge": "Qf",
    "flag-gd": "QK",
    "flag-gb": "QG",
    "flag-ga": "Qr",
    "flag-fr": "Qb",
    "flag-fo": "Qy",
    "flag-fm": "QU",
    "flag-fk": "Qj",
    "flag-fj": "QW",
    "flag-fi": "Qu",
    "flag-fam": "eS",
    "flag-europeanunion": "ec",
    "flag-et": "eq",
    "flag-es": "eZ",
    "flag-er": "eR",
    "flag-england": "eQ",
    "flag-eh": "ee",
    "flag-eg": "eB",
    "flag-ee": "eE",
    "flag-ec": "ed",
    "flag-dz": "eI",
    "flag-do": "et",
    "flag-dm": "eM",
    "flag-dk": "eP",
    "flag-dj": "el",
    "flag-de": "eX",
    "flag-cz": "eC",
    "flag-cy": "ek",
    "flag-cx": "eJ",
    "flag-cv": "es",
    "flag-cu": "eF",
    "flag-cs": "eg",
    "flag-cr": "eD",
    "flag-co": "eV",
    "flag-cn": "eL",
    "flag-cm": "eT",
    "flag-cl": "ez",
    "flag-ck": "ea",
    "flag-ci": "eH",
    "flag-cg": "eO",
    "flag-cf": "em",
    "flag-cd": "ep",
    "flag-cc": "ex",
    "flag-catalonia": "ew",
    "flag-ca": "eN",
    "flag-bz": "ev",
    "flag-by": "eo",
    "flag-bw": "en",
    "flag-bv": "ei",
    "flag-bt": "eA",
    "flag-bs": "eY",
    "flag-br": "eh",
    "flag-bo": "ef",
    "flag-bn": "eK",
    "flag-bm": "eG",
    "flag-bj": "er",
    "flag-bi": "eb",
    "flag-bh": "ey",
    "flag-bg": "eU",
    "flag-bf": "ej",
    "flag-be": "eW",
    "flag-bd": "eu",
    "flag-bb": "BS",
    "flag-ba": "Bc",
    "flag-az": "Bq",
    "flag-ax": "BZ",
    "flag-aw": "BR",
    "flag-au": "BQ",
    "flag-at": "Be",
    "flag-as": "BB",
    "flag-ar": "BE",
    "flag-ao": "Bd",
    "flag-an": "BI",
    "flag-am": "Bt",
    "flag-al": "BM",
    "flag-ai": "BP",
    "flag-ag": "Bl",
    "flag-af": "BX",
    "flag-ae": "BC",
    "flag-ad": "Bk",
    "flag-np": "BJ",
    "flag-ch": "Bs"
  };
  _exports.default = _default;
});